var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"department-card-doctors"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"item-text":"specialization","item-value":"specializationId","items":_vm.filterArr(_vm.objSpecializations),"label":"Выберите специализацию","outlined":"","flat":""},on:{"input":_vm.dataFilter},model:{value:(_vm.selectedSpecialization),callback:function ($$v) {_vm.selectedSpecialization=$$v},expression:"selectedSpecialization"}})],1)],1),(_vm.objDepartmentDoctors.length)?_c('v-row',_vm._l((_vm.objDepartmentDoctors),function(data,index){return _c('v-col',{key:index,attrs:{"cols":"12"}},[_c('v-card',{staticClass:"media",attrs:{"hover":"","outlined":""}},[_c('div',{staticClass:"media-body"},[_c('v-row',[_c('v-col',{staticClass:"text-left",attrs:{"cols":"3","sm":"2"}},[_c('v-avatar',{staticClass:"profile",attrs:{"color":"grey","size":"64","tile":""}},[(data.avatar)?_c('v-img',{attrs:{"src":'https://old.medveddoc.com' + data.avatar}}):_c('v-img',{attrs:{"src":require("images/base_img_doctor.webp")}})],1),_c('div',[_c('div',{staticClass:"display-m-none"})])],1),_c('v-col',{attrs:{"cols":"8","sm":"4"}},[_c('router-link',{attrs:{"to":{
                    path: '/doctor/' + data.userId,
                    params: { userId: data.userId },
                  },"target":"_blank"}},[_c('h5',{staticClass:"media-heading",attrs:{"itemprop":"description"}},[_vm._v(" "+_vm._s(data.name)+" ")])]),_c('span',{staticClass:"doctor-specialization"},[_vm._v(" "+_vm._s(data.specializationsString.join(", "))+" ")])],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"3"}},[_c('span',{staticClass:"doctor-degree"},[_vm._v(" "+_vm._s(_vm.fullCategory(data.doctorCategory, data.academicDegree)))])]),_c('v-col',{staticClass:"text-center btn-container d-flex flex-row flex-sm-column align-center justify-center",attrs:{"cols":"12","sm":"3"}},[_c('v-btn',{staticClass:"btn-detail mr-2 mb-0 mb-sm-2 mr-sm-0",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.openDrawerDetail(
                      data.userId,
                      data.name,
                      data.specialization,
                      data.avatar
                    )}}},[_vm._v(" Подробнее ")]),(data.hiddenLk == 0)?_c('v-btn',{staticClass:"btn-order",attrs:{"outlined":"","color":"success"},on:{"click":function($event){return _vm.openDrawerFast(
                      data.userId,
                      data.name,
                      data.specialization,
                      data.avatar
                    )}}},[_vm._v(" Записаться ")]):_vm._e(),(data.hiddenLk == 1)?_c('v-btn',{staticStyle:{"font-size":"10px"},attrs:{"outlined":"","color":"red"}},[_vm._v(" Запись ограничена ")]):_vm._e()],1)],1)],1)])],1)}),1):_c('v-row',[_c('v-col',[_c('p',[_vm._v("К сожаланию, данные отсутствуют.")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }